<i18n locale="en">
{
  "filter-label": "Filter values",
  "placeholders": {
    "fallback": "Select a value",
    "filter": "Filter values..."
  }
}
</i18n>

<template>
  <div ref="container" class="own-dropdown">
    <OwnCard
      class="w-full own-dropdown__trigger"
      :class="[
        showPicker && 'own-dropdown__trigger--active',
        props.error && 'own-dropdown__trigger--error',
      ]"
      :aria-invalid="props.error"
      :border-color="props.error ? 'danger' : undefined"
      el="button"
      control
      @click="togglePickerVisibility(!showPicker)"
    >
      <div class="flex-row gap-2 align-center justify-between w-full">
        <OwnInputContainer
          v-if="showFilterInput"
          :label="$t('filter-label')"
          sr-only
        >
          <OwnInput
            ref="filterInputRef"
            v-model="filterValue"
            class="w-full"
            :placeholder="$tc('placeholders.filter')"
            borderless
            @keyup.space.stop.prevent
          />
        </OwnInputContainer>

        <template v-else>
          <div class="flex-row gap-2 align-center justify-between w-full">
            <div class="flex-row own-dropdown__trigger-text-container">
              <slot name="trigger-left" :item="selectedValue">
                <Component
                  :is="selectedValue?.icon"
                  v-if="selectedValue?.icon"
                  class="flex-shrink-0"
                  size="16"
                />
              </slot>

              <OwnType
                class="text--truncate"
                :text="triggerLabel ?? $t('placeholders.fallback')"
                el="span"
                variant="subtitle"
                :color="placeholderColor"
              />
            </div>

            <PhCaretDown
              class="text-color-primary flex-shrink-0"
              size="16"
              weight="bold"
            />
          </div>
        </template>
      </div>
    </OwnCard>

    <OwnDropdownMenu
      v-if="showPicker"
      ref="content"
      class="own-dropdown__content"
      :active="modelVal?.toString()"
      :items="filteredOptions"
      :visible-options="props.visibleOptions"
      @selected="onItemSelect"
    >
      <template #left="{ item }">
        <slot name="left" :item="item" />
      </template>

      <template #actions>
        <slot name="actions" :close="closePicker" />
      </template>

      <template #right="{ item }">
        <slot name="right" :item="item" />
      </template>
    </OwnDropdownMenu>
  </div>
</template>

<script lang="ts" setup generic="T extends Record<string, string | undefined>">
import { useVModel, onClickOutside } from '@vueuse/core'
import { PhCaretDown } from 'phosphor-vue/dist/phosphor-vue.esm'
import { computed, ref, nextTick } from 'vue'

import { OwnCard } from '../OwnCard'
import { OwnInput } from '../OwnInput'
import { OwnInputContainer } from '../OwnInputContainer'
import { OwnType } from '../OwnType'

import OwnDropdownMenu from './components/OwnDropdownMenu.vue'
import { OwnDropdownItem } from './types'

const props = withDefaults(
  defineProps<{
    accessibilityId?: string
    error?: boolean
    filterKeys?: (keyof T)[]
    options: (OwnDropdownItem & T)[]
    placeholder?: string
    value?: string | number
    visibleOptions?: number
  }>(),
  {
    accessibilityId: undefined,
    error: false,
    filterKeys: () => [],
    placeholder: undefined,
    value: undefined,
    visibleOptions: 4,
  }
)

const modelVal = useVModel(props)

const showPicker = ref<boolean>(false)
const container = ref<HTMLDivElement | null>(null)
const filterInputRef = ref<InstanceType<typeof OwnInput> | null>(null)
const filterValue = ref<string | undefined>(undefined)

const selectedValue = computed(() => {
  return props.options.find((option) => option.value === modelVal.value)
})

const triggerLabel = computed(() => {
  return modelVal.value !== undefined
    ? selectedValue.value?.label
    : props.placeholder
})

const placeholderColor = computed(() => {
  if (props.error) return 'danger'

  return selectedValue.value ? 'primary' : 'secondary'
})

const filterConfigured = computed(() => {
  return props.filterKeys.length > 0
})

const showFilterInput = computed(() => {
  return showPicker.value && filterConfigured.value
})

const filteredOptions = computed(() => {
  if (filterConfigured.value) {
    return props.options.filter((option) => {
      if (
        !filterConfigured.value ||
        filterValue.value === undefined ||
        filterValue.value === ''
      )
        return true

      return props.filterKeys.some((key) => {
        const target = option[key]

        if (target && filterValue.value) {
          return target.toLowerCase().includes(filterValue.value.toLowerCase())
        } else {
          return false
        }
      })
    })
  }

  return props.options
})

const closePicker = () => {
  showPicker.value = false
  filterValue.value = undefined
}

const openPicker = () => {
  showPicker.value = true

  nextTick(() => {
    if (filterInputRef.value) {
      filterInputRef.value.focusElement()
    }
  })
}

const togglePickerVisibility = (newVisibility: boolean) => {
  if (newVisibility) {
    openPicker()
  } else {
    closePicker()
  }
}

const onItemSelect = (itemSelected: OwnDropdownItem) => {
  modelVal.value = itemSelected.value

  closePicker()
}

onClickOutside(container, () => {
  if (showPicker.value) {
    closePicker()
  }
})
</script>

<style lang="scss">
.own-dropdown {
  width: 100%;
  position: relative;

  &__trigger {
    padding: 12px 16px;

    transition: transitionOne(background-color), transitionOne(border);

    &:hover,
    &:active,
    &:focus,
    &--active {
      background-color: $background-highlight;
      border-color: rgba($misc-brand, 0.05);
    }

    &--error {
      border-color: $status-danger;

      &:hover,
      &:active,
      &:focus {
        border-color: $status-danger;
      }
    }
  }

  &__trigger-text-container {
    max-width: calc(100% - 24px);
  }

  &__content {
    width: 100%;

    position: absolute;
    top: 53px; // 8px spacing between trigger and body
    z-index: 500; // Above everything
  }
}
</style>
