<template>
  <div
    class="own-input"
    :class="[
      clamp && 'own-input--clamp',
      isEmpty && 'own-input--empty',
      small && 'own-input--small',
      rounded && 'own-input--rounded',
      isFocused && 'own-input--focus',
      error && 'own-input--error',
      disabled && 'own-input--disabled',
      borderless && 'own-input--borderless',
    ]"
    @click="focusElement"
  >
    <div v-if="$slots.prepend" class="own-input__slot own-input__slot--prepend">
      <!--
      @slot Prepend - Content before leading icon
    -->
      <slot name="prepend"></slot>
    </div>
    <OwnIcon
      v-if="iconLeft"
      class="own-input__icon own-input__icon--prepend"
      :class="[
        error && 'own-input__icon--error',
        disabled && 'own-input__icon--disabled',
      ]"
      :icon="iconLeft"
      :color="iconLeftColor"
    />
    <span
      v-if="startText"
      class="own-input__text own-input__text--prepend text-paragraph"
      :class="[
        error && 'own-input__text--error',
        disabled && 'own-input__text--disabled',
      ]"
      v-text="startText"
    ></span>
    <input
      :id="accessibilityId"
      ref="inputField"
      v-model="inputValue"
      class="own-input__field"
      :class="[lookupFont(font), error && 'own-input__field--error']"
      name="native-input"
      :type="type"
      :step="step"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :max="max"
      :min="min"
      :disabled="disabled"
      :aria-describedby="error ? `${accessibilityId}-message` : undefined"
      @click.stop
      @focus="onFocus"
      @blur="onBlur"
      v-on="inputSafeListeners"
    />
    <span
      v-if="endText"
      class="own-input__text own-input__text--append text-paragraph"
      :class="[
        error && 'own-input__text--error',
        disabled && 'own-input__text--disabled',
      ]"
      v-text="endText"
    ></span>
    <OwnIcon
      v-if="iconRight"
      class="own-input__icon own-input__icon--append"
      :class="[
        error && 'own-input__icon--error',
        disabled && 'own-input__icon--disabled',
      ]"
      :icon="iconRight"
      :color="iconRightColor"
    />
    <div v-if="$slots.append" class="own-input__slot own-input__slot--append">
      <!--
      @slot Append - Content after trailing icon
    -->
      <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
import { colors } from '../Common/color.validator'
import { fontTypes, lookupFont } from '../Common/fonts'
import { VALID_ICONS } from '../Common/utils/VALID_ICONS'
import { OwnIcon } from '../OwnIcon'

export default {
  name: 'OwnInput',
  components: {
    OwnIcon,
  },
  props: {
    /**
     * Accessibility ID
     */
    accessibilityId: { type: String, default: undefined },

    /**
     * Autocomplete data
     */
    autocomplete: { type: String, default: 'off' },

    /**
     * Renders the input without a border
     */
    borderless: { type: Boolean, default: false },

    /**
     * Clamped to size of children
     */
    clamp: { type: Boolean, default: false },

    /**
     * Disables input
     */
    disabled: { type: Boolean, default: false },

    /**
     * Text to show after input
     */
    endText: { type: String, default: undefined },

    /**
     * Has Error
     */
    error: { type: Boolean, default: false },

    font: {
      type: String,
      default: 'paragraph',
      validator: (v) => fontTypes.includes(v),
    },

    /**
     * Icon to show after the end text
     */
    iconLeft: {
      type: String,
      default: undefined,
      validator: (v) => VALID_ICONS.includes(v),
    },

    /**
     * Color Of End Icon
     */
    iconLeftColor: {
      type: String,
      default: 'slate-600',
      validator: colors,
    },

    /**
     * Icon to show before the start text
     */
    iconRight: {
      type: String,
      default: undefined,
      validator: (v) => VALID_ICONS.includes(v),
    },

    /**
     * Color Of Start Icon
     */
    iconRightColor: {
      type: String,
      default: 'slate-600',
      validator: colors,
    },

    max: { type: String, default: undefined },
    min: { type: String, default: undefined },

    /**
     * Placeholder Text
     */
    placeholder: { type: String, default: undefined },

    /**
     * Has rounded outline
     */
    rounded: { type: Boolean, default: false },

    /**
     * Is small input
     */
    small: { type: Boolean, default: false },

    /**
     * Text to show before input
     */
    startText: { type: String, default: undefined },

    /**
     * Step for numeric input
     */
    step: { type: Number, default: undefined },

    /**
     * Input Type
     */
    type: {
      type: String,
      default: 'text',
      validator: (v) =>
        ['text', 'password', 'email', 'number', 'date'].includes(v),
    },

    /**
     * Model Value
     * @model
     */
    value: { type: [String, Number], default: undefined },
  },
  data() {
    return {
      isFocused: false,
    }
  },
  computed: {
    inputSafeListeners() {
      const { $listeners } = this

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { input, ...rest } = $listeners

      return rest
    },
    inputValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    isEmpty() {
      const { inputValue } = this

      return (
        inputValue === null || inputValue === undefined || inputValue === ''
      )
    },
  },
  methods: {
    focusElement() {
      const inputEl = this.$refs.inputField

      if (inputEl) {
        inputEl.focus()
      }
    },
    lookupFont,
    onBlur() {
      this.isFocused = false
    },
    onFocus() {
      this.isFocused = true
    },
  },
}
</script>

<style lang="scss" scoped src="./OwnInput.scss"></style>
