<template>
  <OwnCard
    class="own-table-table-renderer"
    el="table"
    :border-color="border ? undefined : 'transparent'"
    :control="border"
  >
    <span class="own-table-table-renderer__heading-container">
      <OwnStack
        el="tr"
        class="own-table-table-renderer__heading"
        :spacing="options.row.spacing"
        align="center"
        row
      >
        <th
          v-for="column of props.columns"
          :key="column.key"
          class="flex-1"
          :class="[`text--${column?.align ?? 'left'}`]"
          :style="{
            maxWidth: column.minWidth ? undefined : toPx(column.width),
            minWidth: toPx(column.minWidth ?? column.width),
          }"
        >
          <slot :name="`heading.${column.key}`">
            <OwnType
              v-if="!column?.hideLabel"
              class="w-full"
              el="span"
              :text="column?.label ?? '—'"
              :info="column?.info"
              variant="subtitle"
            />
          </slot>
        </th>
      </OwnStack>
    </span>

    <!--  Search returns no results  -->
    <div v-if="!hasItems" class="own-table-table-renderer__no-search-results">
      <template v-if="$slots['no-query-results']">
        <slot name="no-query-results"></slot>
      </template>

      <template v-else-if="$slots['no-data']">
        <slot name="no-data"></slot>
      </template>
    </div>

    <OwnStack
      v-else
      ref="reorderableList"
      el="tbody"
      class="own-table-table-renderer__body"
    >
      <template v-for="(item, rowIdx) of items">
        <OwnStack
          :key="item[itemKey]"
          class="own-table-table-renderer__row"
          :class="[
            hasClick && 'own-table-table-renderer__row--click',
            options.body.dividers && 'own-table-table-renderer__row--dividers',
          ]"
          el="tr"
          spacing="4"
          align="center"
          row
          @keyup.enter="emit('click', item)"
          @click="emit('click', item)"
        >
          <DragHandle v-if="props.reorderable" />

          <OwnStack el="span" :spacing="options.row.spacing" align="center" row>
            <template v-for="(column, idx) of props.columns">
              <td
                :key="`${column.key}-${item[itemKey]}`"
                class="flex-1"
                :style="{
                  maxWidth: column.minWidth ? undefined : toPx(column.width),
                  minWidth: toPx(column.minWidth ?? column.width),
                }"
              >
                <!--  Override specific column (#item.name)  -->
                <slot
                  :name="`item.${column.key}`"
                  :item="item"
                  :row-idx="rowIdx"
                  :is-first="idx === 0"
                >
                  <!--  Override all columns (#item)  -->
                  <slot
                    :column-key="column.key"
                    name="item"
                    :item="item"
                    :is-first="idx === 0"
                  >
                    <DefaultTableCell :item="item" :column="column" />
                  </slot>
                </slot>
              </td>
            </template>
          </OwnStack>
        </OwnStack>
      </template>
    </OwnStack>
  </OwnCard>
</template>

<script lang="ts" setup generic="T extends Record<string, unknown>">
import Sortable, { SortableEvent } from 'sortablejs'
import {
  ComponentPublicInstance,
  computed,
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue'

import { ReorderEvent } from '@/utils/helpers/reorder'

import { OwnCard } from '../../OwnCard'
import { OwnStack } from '../../OwnStack'
import { OwnType } from '../../OwnType'
import DragHandle from '../../reorderable/DragHandle.vue'
import { OwnTableColumnConfig, OwnTableVariants } from '../types'
import { getTableOptions } from '../utils/getTableOptions'

import DefaultTableCell from './DefaultTableCell.vue'

const props = withDefaults(
  defineProps<{
    border: boolean
    columns: OwnTableColumnConfig<T>[]
    hasClick: boolean
    items: T[]
    itemKey: string
    query?: string
    reorderable: boolean
    variant: OwnTableVariants
  }>(),
  {
    query: undefined,
  }
)

const emit = defineEmits<{
  (event: 'click', value: T): void
  (event: 'reorder', value: ReorderEvent): void
}>()

const reorderableList = ref<ComponentPublicInstance | null>(null)

const options = getTableOptions(props.variant)

const hasItems = computed(() => {
  return props.items.length > 0
})

const toPx = (value: number | undefined) => {
  if (value === undefined) return undefined

  return `${value}px`
}

const rowRadius = computed(() => toPx(options.row.radius ?? 0))
const rowPadding = computed(() => toPx(options.row.padding ?? 16))
const bodyPadding = computed(() => toPx(options.body.padding ?? 0))

const mountSortable = () => {
  nextTick(() => {
    if (props.reorderable && reorderableList.value) {
      // Lint issue - there is no-mixed-html here
      // eslint-disable-next-line xss/no-mixed-html
      Sortable.create(reorderableList.value.$el as HTMLElement, {
        animation: 150,
        direction: 'vertical',
        handle: '.drag-handle',
        onEnd: ({ oldIndex, newIndex }: SortableEvent) => {
          if (oldIndex === newIndex) return
          emit('reorder', { newIndex, oldIndex } as ReorderEvent)
        },
      })
    }
  })
}

onMounted(() => {
  mountSortable()
})

watch(hasItems, (newValue) => {
  if (newValue) {
    mountSortable()
  }
})
</script>

<style lang="scss">
.own-table-table-renderer {
  overflow: auto;

  &__heading-container {
    padding: 16px 16px 0 16px;
  }

  &__no-search-results {
    padding: 16px;
  }

  &__heading {
    padding-bottom: 16px;
    border-bottom: 1px dashed $background-divider;
  }

  &__body {
    padding: v-bind(bodyPadding);
  }

  &__row {
    border-radius: v-bind(rowRadius);
    padding: v-bind(rowPadding);

    &--click {
      cursor: pointer;

      &:hover {
        background-color: $background-secondary;
      }
    }

    &--dividers {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 16px;
        background: $background-divider;
        height: 1px;
        width: calc(100% - 32px);
      }
    }

    &:last-child {
      &:after {
        height: 0;
      }
    }
  }
}
</style>
